import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Select,
  Button as BtnAntD,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useCheckPermission from "hooks/useCheckPermission";
import useDebounce from "hooks/useDebounce";
import { getAllSampleCollector } from "store";
import { clientLookUp } from "store";
import { EmptyData } from "components/jkt/global/emptyData";
import CreateUpdateSampleCollector from "./createUpdateSampleCollector";
import decodedUserDetails from "utils/decodedUserDetails";
import { useCurrentHeight } from "utils/getScreenSize";
import useTranslation from "hooks/useTranslation";

const GetAllSampleCollectors = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:SampleCollector:Modify"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = React.useState("firstName");
  const [sortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sampleCollectorData, setSampleCollectorData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  // eslint-disable-next-line
  const clientId = decodedUserDetails().clientId;
  const [clientData, setClientData] = React.useState([]);
  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(null);
  const { getTranslation } = useTranslation("SampleCollectorDataTable");

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (
      getAllSampleCollector.pending().type ===
      "sample-collectors/get-all-sample-collectors/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(clientLookUp()).then((data) => {
      setClientData(data?.payload?.data);
    });
    dispatch(
      getAllSampleCollector({
        clientId: clientId,
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (
        element.type === "sample-collectors/get-all-sample-collectors/fulfilled"
      ) {
        setIsLoading(false);
        setSampleCollectorData(element?.payload?.data?.items);
        setTotal(element?.payload?.data?.totalPages * pageSize);
      }
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
    clientId,
  ]);

  const formattedSampleCollectorData = sampleCollectorData?.map((item) => ({
    ...item,
    sampleCollectorName: `${item?.firstName} ${
      item?.lastName === null ? "" : item?.lastName
    }`,
  }));

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Sample Collector Detected"
      action={showCreateUpdate}
      btnTitle="Add New Province"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(null);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(null);
    setIsForUpdate(false);
  };

  const handleUpdate = (selectedData) => {
    setIsForUpdate(true);
    setIdForUpdate(selectedData);
    showCreateUpdate();
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const columns = [
    {
      title: getTranslation("Sample Collector Name"),
      dataIndex: "sampleCollectorName",
      key: "sampleCollectorName",
      width: 60,
    },
    {
      title: getTranslation("Collector Type"),
      dataIndex: "collectorType",
      key: "collectorType",
      width: 120,
    },
    {
      title: getTranslation("Status"),
      dataIndex: "isActive",
      key: "isActive",
      width: 45,
      render: (rec, _) => (
        <>
          {rec ? (
            <Tag color="success">{getTranslation("Active")}</Tag>
          ) : (
            <Tag>{getTranslation("Inactive")}</Tag>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 50,
      render: (rec, row) => (
        <>
          {accessRightsToModify && (
            <BtnAntD
              size="small"
              icon={<EditIcon fontSize="inherit" />}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              onClick={() => {
                handleUpdate(row);
              }}
            >
              {getTranslation("Update")}
            </BtnAntD>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            placeholder={getTranslation("Search Sample Collector")}
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "firstName",
                label: getTranslation("Name"),
              },
              {
                value: "collectorType",
                label: getTranslation("Collector"),
              },
              {
                value: "isActive",
                label: getTranslation("Status"),
              },
            ]}
          />
          {/* <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          /> */}
        </Grid>

        {sampleCollectorData.length !== 0 && accessRightsToModify && (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Sample Collector
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={formattedSampleCollectorData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateSampleCollector
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
      />
    </>
  );
};
export default GetAllSampleCollectors;
