import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import useTranslation from "hooks/useTranslation";
import CloseIcon from "@mui/icons-material/Close";
import { updateClientAffiliate } from "store";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import { createClientAffiliate } from "store";

const FORM_INPUTS = {
  affiliateCode: null,
  affiliateName: null,
};
const CreateUpdateAffiliateClientModal = ({
  handleCloseModal,
  handleFetchClientAffiliatesLookup,
  handleSelectAffiliateClient,
  isForUpdate,
  dataForUpdate,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputs = useSelector((state) => state.inputs);
  const [formInputs, setFormInputs] = useState(FORM_INPUTS);
  const dispatch = useDispatch();
  const { getGlobalTranslation } = useTranslation("PatientPage");

  const formInputStyle = {
    width: "100%",
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "2px solid",
        borderColor: "darkBlue.secondary",
      },
    },
  };

  const inputLabelStyle = {
    fontWeight: "bold",
    color: "darkBlue.main",
  };

  const handleCloseFormModal = () => {
    handleCloseModal();
    setFormInputs(FORM_INPUTS);
  };

  const onChange = (e) => {
    const { value, id } = e.target;
    setFormInputs({ ...formInputs, [id]: value });
  };

  const onSubmit = () => {
    if (isForUpdate) {
      if (
        updateClientAffiliate.pending().type ===
        "client-affiliates/update-client-affiliate/pending"
      ) {
        setIsLoading(true);
      }
      dispatch(
        updateClientAffiliate({
          id: dataForUpdate?.id,
          affiliateCode: formInputs?.affiliateCode,
          affiliateName: formInputs?.affiliateName,
        })
      ).then((res) => {
        console.log(res?.payload?.data);
        if (res?.payload?.success) {
          notification.success({
            message: "Updated Affiliated Client",
            description: `Successfully updated ${res?.payload?.data?.affiliateName}`,
          });
          setIsLoading(false);
          handleFetchClientAffiliatesLookup();
          handleCloseFormModal();
          handleSelectAffiliateClient({
            id: res?.payload?.data?.id,
            label: `${res?.payload?.data?.affiliateCode} - ${res?.payload?.data?.affiliateName}`,
          });
        }

        if (!res.payload?.success) {
          setIsLoading(false);
          notification.error({
            message: "Failed to Update Affiliated Client",
            description: errorMessagesDescription(
              res?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createClientAffiliate.pending().type ===
        "client-affiliates/create-client-affiliate/pending"
      ) {
        setIsLoading(true);
      }

      dispatch(createClientAffiliate(formInputs)).then((res) => {
        if (res?.payload?.success) {
          notification.success({
            message: "Created Affiliated Client",
            description: `Successfully created ${formInputs.affiliateName}`,
          });
          handleFetchClientAffiliatesLookup();
          setIsLoading(false);
          handleCloseFormModal();
          handleSelectAffiliateClient({
            id: res?.payload?.data?.id,
            label: `${res?.payload?.data?.affiliateCode} - ${res?.payload?.data?.affiliateName}`,
          });
        }
        if (!res.payload?.success) {
          setIsLoading(false);
          notification.error({
            message: "Failed to Create Affiliated Client",
            description: errorMessagesDescription(
              res?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    }
  };

  useEffect(() => {
    if (isForUpdate) {
      setFormInputs({
        affiliateCode: dataForUpdate?.affiliateCode,
        affiliateName: dataForUpdate?.affiliateName,
      });
    }
  }, [isForUpdate, dataForUpdate]);

  return (
    <Box sx={{ padding: 3 }}>
      <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
        <Typography
          variant="h6"
          display="block"
          sx={{ fontWeight: "500", color: "#294991" }}
        >
          {isForUpdate ? "Edit Affiliate Client" : "Add Affiliate Client"}
        </Typography>
      </Divider>
      <Grid spacing={2} container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography variant="p" sx={inputLabelStyle}>
            Affiliate Code
          </Typography>

          <TextField
            sx={formInputStyle}
            value={formInputs.affiliateCode}
            autoSave="off"
            placeholder="Enter affiliate code..."
            size="small"
            variant="outlined"
            id="affiliateCode"
            onChange={onChange}
            autoFocus
          />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography variant="p" sx={inputLabelStyle}>
            Affiliate Name
          </Typography>

          <TextField
            sx={formInputStyle}
            value={formInputs.affiliateName}
            autoSave="off"
            placeholder="Enter affiliate name..."
            size="small"
            variant="outlined"
            id="affiliateName"
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 5,
          marginTop: !inputs.isPatientHasAnAcceptedOrder && "1.5rem",
        }}
      >
        <LoadingButton
          size="medium"
          type="button"
          loading={isLoading}
          variant="contained"
          endIcon={isForUpdate ? <SaveIcon /> : <SendIcon />}
          onClick={onSubmit}
        >
          {isForUpdate
            ? getGlobalTranslation("Save Changes")
            : getGlobalTranslation("Submit")}
        </LoadingButton>
        <Button
          onClick={handleCloseFormModal}
          size="medium"
          variant="dark"
          endIcon={<CloseIcon />}
        >
          {getGlobalTranslation("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdateAffiliateClientModal;
