import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/jkt/global/menuBar";
import { theme } from "components/jkt/global/theme";
import V2sessionTimeout from "components/jkt/global/v2SessionTimeout";
import TableContentLayout from "views/jkt/layout/tableContentLayout";
import useTranslation from "hooks/useTranslation";
import GetAllSupplyRequest from "components/jkt/supplyRequest/getAllSupplyRequest";

const SupplyRequestPage = () => {
  const { getTranslation } = useTranslation("SidebarItems");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={getTranslation("SupplyRequest")} />
        <TableContentLayout>
          <GetAllSupplyRequest />
        </TableContentLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default SupplyRequestPage;
