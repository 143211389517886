import { Button, Grid } from "@mui/material";
import {
  ConfigProvider,
  Input,
  Select,
  Table,
  Button as BtnAntD,
  Space,
} from "antd";
import { EmptyData } from "components/jkt/global/emptyData";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import useDebounce from "hooks/useDebounce";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllClientAffiliates } from "store";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { useCurrentHeight } from "utils/getScreenSize";
import useCheckPermission from "hooks/useCheckPermission";
import CreateUpdateAffiliateClient from "./createUpdateAffiliateClient";

const GetAllClientAffiliates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Administration:ClientAffiliate:Modify"
  );
  const { claims } = useSelector((state) => state.auth);
  const { isClientKedoya } = parsedClientDetails();
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [pageSize, setPageSize] = useState(10);
  const [sortStr, setSortStr] = useState("Id");
  const [sortDirection, setSortDirection] = useState(0);
  const [clientAffiliatesData, setClientAffiliatesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [dataForUpdate, setDataForUpdate] = useState(null);
  const [
    openCreateUpdateAffilateClientModal,
    setOpenCreateUpdateAffiliateClientModal,
  ] = useState(false);
  // const [selectedClientAffiliateDetails, setSelectedClientAffiliateDetails] =
  //   useState(null);
  const showClientAffiliatesItem =
    claims?.find(
      (claim) => claim?.permissionKey === "Administration:ClientAffiliate:Read"
    )?.isGranted && isClientKedoya;

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getAllClientAffiliates.pending().type ===
      "client-affiliates/get-all-client-affiliates/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      getAllClientAffiliates({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (
        element.type === "client-affiliates/get-all-client-affiliates/fulfilled"
      ) {
        setIsLoading(false);
        setClientAffiliatesData(element?.payload?.data?.items);
        setTotal(element.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  const handleCancel = () => {
    setOpenCreateUpdateAffiliateClientModal(false);
    setDataForUpdate(null);
  };

  const showCreateUpdate = () => {
    setOpenCreateUpdateAffiliateClientModal(true);
  };

  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdateAffiliateClientModal(false);
  };

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  useEffect(() => {
    if (isUserLoggedIn() && !showClientAffiliatesItem) {
      navigate("/test-request");
    }
  }, [navigate, showClientAffiliatesItem]);

  const onChange = (page) => {
    setCurrent(page);
  };

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      action={showCreateUpdate}
      description="No Client Detected"
      btnTitle="Add New Client"
      renderButton={accessRightsToModify}
    />
  );

  // const handleChangeSortField = (value) => {
  //   setSortStr(value);
  // };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const handleUpdate = (rowVal) => {
    setDataForUpdate(rowVal);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Affiliate Code",
      dataIndex: "affiliateCode",
      key: "affiliateCode",
    },
    {
      title: "Affiliate Name",
      dataIndex: "affiliateName",
      key: "affiliateName",
    },
    {
      title: "Client Id",
      dataIndex: "clientId",
      key: "clientId",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 190,
      render: (_, row) => (
        <Space direction="horizontal" size={2}>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              icon={<EditIcon fontSize="inherit" />}
            >
              Update
            </BtnAntD>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            placeholder="Search Affiliate Client"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>
        {clientAffiliatesData?.length !== 0 && accessRightsToModify && (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              onClick={showCreateUpdate}
              startIcon={<AddOutlinedIcon />}
              variant="text"
            >
              New Affiliate Client
            </Button>
          </Grid>
        )}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={clientAffiliatesData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateAffiliateClient
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdateAffilateClientModal}
        handleSubmit={handleSubmit}
        isForUpdate={dataForUpdate !== null}
        dataForUpdate={dataForUpdate}
      />
    </>
  );
};

export default GetAllClientAffiliates;
