export const renderSupplyRequestAuditTrailAction = (selectedAction) => {
  const auditTrailActionsMap = {
    CreatedSupplyRequest: "Created Supply Request",
    ReviewedSupplyRequest: "Reviewed Supply Request",
    EvaluatedSupplyRequest: "Evaluated Supply Request",
    PartiallyEvaluatedSupplyRequest: "Partially Evaluated Supply Request",
    CancelledSupplyRequest: "Cancelled Supply Request",
    CancelledRequestedItem: "Cancelled Requested Item",
    CompletedSupplyRequest: "Completed Supply Request",
    PartiallyCompletedSupplyRequest: "Partially Completed Supply Request",
    UpdatedSupplyRequest: "Updated Supply Request",
    PickedUpRequestedItem: "Picked Up Requested Item",
    UpdatedRequestedItem: "Updated Requested Item",
    DeliveredRequestedItem: "Delivered Requested Item",
    ApprovedRequestedItem: "Approved Requested Item",
    ReleasedRequestedItem: "Released Requested Item",
    RejectedRequestedItem: "Rejected Requested Item",
    RequestedItemStatusUpdated: "Requested Item Status Updated",
    SupplyRequestStatusUpdated: "Supply Request Status Updated",
    RequestedItemReadyForDelivery: "Requested Item Ready For Delivery",
  };

  const selectedAuditTrailAction = auditTrailActionsMap[selectedAction];
  const validatedSelectedAuditTrailAction =
    selectedAuditTrailAction === undefined
      ? selectedAction
      : selectedAuditTrailAction;

  return validatedSelectedAuditTrailAction;
};
