import {
  Autocomplete,
  Box,
  createFilterOptions,
  InputAdornment,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientAffiliatesLookUp } from "store";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { handleChangeFields } from "store/slice/inputSlice";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Modal } from "antd";
import CreateUpdateAffiliateClientModal from "./createUpdateAffiliateClientModal";

const AffiliateClientSearchInput = ({
  searchAffiliateClientInput,
  setSearchAffiliateClientInput,
  affliateClientDataForUpdate,
  setAffiliateClientDataForUpdate,
}) => {
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const { clientDetails } = parsedClientDetails();
  const inputs = useSelector((state) => state.inputs);
  const [affiliateClientsData, setAffiliateClientsData] = useState([]);
  const [showCreateUpdateAffiliateModal, setShowCreateUpdateAffiliateModal] =
    useState(false);
  const emptySearchText = searchAffiliateClientInput?.trim().length === 0;
  const isForUpdate = affliateClientDataForUpdate;

  const handleFetchClientAffiliatesLookup = () => {
    dispatch(clientAffiliatesLookUp(clientDetails?.id)).then((res) => {
      if (res?.payload?.success) {
        const options = res?.payload?.data?.map((item) => ({
          ...item,
          label: `${item?.affiliateCode} - ${item?.affiliateName}`,
        }));

        setAffiliateClientsData(options);
      }
    });
  };
  useEffect(() => {
    handleFetchClientAffiliatesLookup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDetails?.id, dispatch]);

  const handleSelectAffiliateClient = (option) => {
    dispatch(
      handleChangeFields({
        clientAffiliateId: option.id,
      })
    );
    setSearchAffiliateClientInput(option.label);
    setAffiliateClientDataForUpdate(option);
  };

  const handleOpenCreateUpdateAffiliateClientModal = () => {
    setShowCreateUpdateAffiliateModal(true);
  };

  const handleCloseCreateUpdateAffiliateClientModal = () => {
    setShowCreateUpdateAffiliateModal(false);
  };

  useEffect(() => {
    if (emptySearchText) {
      dispatch(
        handleChangeFields({
          clientAffiliateId: null,
        })
      );
      setAffiliateClientDataForUpdate(null);
    }
  }, [emptySearchText, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.3rem",
        justifyContent: "start",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Autocomplete
        disableClearable
        disablePortal
        clearOnBlur
        size="small"
        value={searchAffiliateClientInput}
        options={affiliateClientsData}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              handleOpenCreateUpdateAffiliateClientModal();
            });
          } else if (newValue && newValue.inputValue) {
            handleOpenCreateUpdateAffiliateClientModal();
          } else {
            handleSelectAffiliateClient(newValue);
          }
        }}
        sx={{ width: "100%" }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              addNewClient: "Add New Client",
            });
          }
          return filtered;
        }}
        getOptionDisabled={(option) => inputs.clientAffiliateId === option.id}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          if (option.label) {
            return option.label;
          }
          if (option.addNewClient) {
            return option.addNewClient;
          }
        }}
        renderOption={(props, client) => (
          <li
            key={client.label}
            style={{
              margin: "0.2rem",
              backgroundColor: client?.addNewClient?.includes("Add New Client")
                ? "#c1dbff"
                : "",
            }}
            {...props}
          >
            {client?.addNewClient?.includes("Add New Client")
              ? client.addNewClient
              : client.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            value={searchAffiliateClientInput}
            onChange={(e) => setSearchAffiliateClientInput(e.target.value)}
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "2px solid",
                  borderColor: "darkBlue.secondary",
                },
              },
              borderRadius: "5px",
              outline: "none",
              width: "100%",
            }}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment
                    sx={{ transform: "translateX(30px)" }}
                    position="end"
                  >
                    {inputs.clientAffiliateId !== null ? (
                      <CloseRoundedIcon
                        sx={{
                          color: "lightBlue.main",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          dispatch(
                            handleChangeFields({
                              clientAffiliateId: null,
                            })
                          );
                          setSearchAffiliateClientInput("");
                          setAffiliateClientDataForUpdate(null);
                        }}
                      />
                    ) : (
                      <SearchOutlinedIcon
                        sx={{
                          color: "lightBlue.main",
                          pointerEvents: "none",
                        }}
                      />
                    )}
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
      <Tooltip
        title="Add New Affiliate Client"
        placement="right-end"
        TransitionComponent={Zoom}
        arrow
      >
        <Box
          sx={{
            backgroundColor: "darkBlue.main",
            padding: "0.7rem",
            height: "2.45rem",
            borderRadius: "0.3rem",
            cursor: "pointer",
            transition: "0.3s ease-in-out",
            ":hover": {
              backgroundColor: "darkBlue.secondary",
            },
          }}
          onClick={handleOpenCreateUpdateAffiliateClientModal}
        >
          {!isForUpdate ? (
            <PersonAddOutlinedIcon
              sx={{
                fontSize: "20px",
                marginBottom: "0.1rem",
                color: "white",
                transform: "translateY(-3px)",
              }}
            />
          ) : (
            <EditOutlinedIcon
              sx={{
                fontSize: "20px",
                marginBottom: "0.3rem",
                color: "white",
                transform: "translateY(-3px)",
              }}
            />
          )}
        </Box>
      </Tooltip>
      <Modal
        closable
        open={
          !isForUpdate
            ? showCreateUpdateAffiliateModal
            : showCreateUpdateAffiliateModal &&
              affliateClientDataForUpdate !== null
        }
        width={500}
        footer={null}
        onCancel={handleCloseCreateUpdateAffiliateClientModal}
        centered
      >
        <CreateUpdateAffiliateClientModal
          handleCloseModal={handleCloseCreateUpdateAffiliateClientModal}
          handleFetchClientAffiliatesLookup={handleFetchClientAffiliatesLookup}
          handleSelectAffiliateClient={handleSelectAffiliateClient}
          dataForUpdate={affliateClientDataForUpdate}
          isForUpdate={isForUpdate}
        />
      </Modal>
    </Box>
  );
};

export default AffiliateClientSearchInput;
