import * as React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Button as BtnAntD,
  Space,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useDebounce from "hooks/useDebounce";
import CreateUpdateHealthcareProvider from "./createUpdateHealthcareProvider";
import useCheckPermission from "hooks/useCheckPermission";
import HealthcareProviderDetails from "./healthcareProviderDetails";
import { useCurrentHeight } from "utils/getScreenSize";
import useTranslation from "hooks/useTranslation";
import { EmptyData } from "components/jkt/global/emptyData";
import { getAllHealthcareProvider } from "store";
import { clientLookUp } from "store";

const GetAllHealthcareProvider = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:HealthcareProvider:Modify"
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr] = React.useState("firstName");
  const [sortDirection] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [healthcareProviderData, setHealthcareProviderData] = React.useState(
    []
  );
  const [clientData, setClientData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [current, setCurrent] = React.useState(1);
  const [isForUpdate, setIsForUpdate] = React.useState(false);
  const [idForUpdate, setIdForUpdate] = React.useState(null);
  const { getTranslation } = useTranslation("HealthcareProviderDataTable");

  const LoadData = React.useCallback(() => {
    //HANDLE LOADING
    if (
      getAllHealthcareProvider.pending().type ===
      "healthcareProvider/get-all-healthcare-provider/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(clientLookUp()).then((res) => {
      setClientData(res.payload.data);
    });
    dispatch(
      getAllHealthcareProvider({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      const isFetchSuccessful = element?.payload?.success;
      if (isFetchSuccessful) {
        setIsLoading(false);
        setHealthcareProviderData(element?.payload?.data?.items);
        setTotal(element.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  const getClientName = (selectedClientId) => {
    const clientName = clientData?.find(
      (c) => c.id === selectedClientId
    )?.clientName;
    return clientName;
  };

  const formattedHealthcareProviderData = healthcareProviderData?.map(
    (healthcareProviderData) => ({
      ...healthcareProviderData,
      clientName: getClientName(healthcareProviderData?.clientId),
      physicianName: `${healthcareProviderData?.firstName} ${
        healthcareProviderData?.lastName === null
          ? ""
          : healthcareProviderData?.lastName
      }`,
    })
  );

  React.useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  //MODAL FOR INFO USER
  const [openInfo, setOpenInfo] = React.useState(false);
  const [selectedInfoDetails] = React.useState(null);

  const handleOkInfo = () => {
    setOpenInfo(false);
  };
  const handleCancelInfo = () => {
    setOpenInfo(false);
  };

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Healthcare Provider Detected"
      action={showCreateUpdate}
      btnTitle="Add New Healthcare Provider"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = React.useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(null);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
  };

  const handleUpdate = (selectedData) => {
    setIsForUpdate(true);
    setIdForUpdate(selectedData);
    showCreateUpdate();
  };

  const columns = [
    {
      title: getTranslation("Physician Name"),
      dataIndex: "physicianName",
      key: "physicianName",
      width: 150,
    },
    {
      title: getTranslation("Status"),
      dataIndex: "isActive",
      key: "isActive",
      width: 30,
      render: (rec, row) => (
        <>
          {rec ? (
            <Tag color="success">{getTranslation("Active")}</Tag>
          ) : (
            <Tag>{getTranslation("Inactive")}</Tag>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 150,
      render: (rec, row) => (
        <Space direction="horizontal" size={2}>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              icon={<EditIcon fontSize="inherit" />}
              className="hover:bg-orange-500 bg-orange-600"
              style={{
                border: "1px solid #ed6c02",
                color: "white",
              }}
              onClick={() => {
                handleUpdate(row);
              }}
            >
              {getTranslation("Update")}
            </BtnAntD>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder={getTranslation("Search Healthcare Provider")}
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        {healthcareProviderData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              {getTranslation("New Healthcare Provider")}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={formattedHealthcareProviderData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <HealthcareProviderDetails
        selectedInfoDetails={selectedInfoDetails}
        openInfo={openInfo}
        handleOkInfo={handleOkInfo}
        handleCancelInfo={handleCancelInfo}
        getClientName={getClientName}
      />
      <CreateUpdateHealthcareProvider
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientData={clientData}
      />
    </>
  );
};
export default GetAllHealthcareProvider;
