import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_AFFILIATES } from "../../config/connString";
import useAxios from "../../utils/useAxios";

const deleteClientAffiliate = createAsyncThunk(
  "client-affiliates/delete-client-affiliate",
  async (id) => {
    try {
      const api = useAxios();
      const response = await api.delete(`${URL_CLIENT_AFFILIATES}/${id}`);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { deleteClientAffiliate };
