import { Button, Form, Input, Modal, Popconfirm } from "antd";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import notification from "components/jkt/global/openNotification";
import useCheckPermission from "hooks/useCheckPermission";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteClientAffiliate } from "store";
import { createClientAffiliate } from "store";
import { updateClientAffiliate } from "store";

const CreateUpdateAffiliateClient = (props) => {
  const {
    openCreateUpdate,
    isForUpdate,
    handleCancel,
    handleSubmit,
    dataForUpdate,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const isAuthorizedToDelete = handleVerifyPermission(
    "Administration:ClientAffiliate:Delete"
  );

  const handleCloseModal = () => {
    handleCancel();
    clearFormInputs();
  };

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      affiliateCode: dataForUpdate.affiliateCode,
      affiliateName: dataForUpdate.affiliateName,
    });
  }, [form, dataForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const clearFormInputs = () => {
    form.setFieldsValue({
      affiliateName: "",
      affiliateCode: "",
    });
  };

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateClientAffiliate.pending().type ===
        "client-affiliates/update-client-affiliate/pending"
      ) {
        setLoading(true);
      }
      dispatch(
        updateClientAffiliate({
          id: dataForUpdate?.id,
          affiliateCode: values?.affiliateCode,
          affiliateName: values?.affiliateName,
        })
      ).then((res) => {
        if (res?.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Affiliated Client",
            description: `Successfully updated ${values?.affiliateName}`,
          });
          handleCloseModal();
        }
        if (!res.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Update Affiliated Client",
            description: errorMessagesDescription(
              res?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createClientAffiliate.pending().type ===
        "client-affiliates/create-client-affiliate/pending"
      ) {
        setLoading(true);
      }

      dispatch(createClientAffiliate(values)).then((res) => {
        if (res?.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Affiliated Client",
            description: `Successfully created ${values.affiliateName}`,
          });
          handleCloseModal();
        }
        if (!res.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Create Affiliated Client",
            description: errorMessagesDescription(
              res?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteClientAffiliate.pending().type ===
      "client-affiliates/delete-client-affiliate/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteClientAffiliate(dataForUpdate.id)).then((res) => {
      if (res.payload?.success) {
        handleSubmit();
        handleCloseModal();
        setLoading(false);
        notification.success({
          message: "Deleted Affiliated Client",
          description: `Successfully deleted ${dataForUpdate.name}`,
        });
      }
      if (!res.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to Delete Affiliated Client",
          description: errorMessagesDescription(
            res?.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      onCancel={handleCloseModal}
      title={
        isForUpdate ? "Update Affiliate Client" : "Create Affiliate Client"
      }
      footer={null}
    >
      <Form
        name="affiliate-client-form"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
      >
        {/* Affiliate Code */}
        <Form.Item
          label="Affiliate Code"
          name="affiliateCode"
          rules={[
            {
              required: true,
              message: <i>{"Please input Affiliate Code."}</i>,
            },
          ]}
        >
          <Input autoFocus placeholder="Affiliate Code" />
        </Form.Item>

        {/* Affiliate Name */}
        <Form.Item
          label="Affiliate Name"
          name="affiliateName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Affiliate Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Affiliate Name" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {isAuthorizedToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete Affiliated Client"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateAffiliateClient;
