import { useDispatch } from "react-redux";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeOrderData } from "store/slice/orderSlice";
import { handleChangePhysicianFields } from "store/slice/physicianInputSlice";
import { handleChangeSampleCollectorFields } from "store/slice/sampleCollectorInputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";
import { handleChangeSpecimenFields } from "store/slice/specimenInputSlice";

const useClearReduxStore = () => {
  const dispatch = useDispatch();

  const handleResetReduxStore = () => {
    localStorage.removeItem("searchCollector");
    localStorage.removeItem("selectedParentCollapseKey");

    dispatch(
      handleChangeFields({
        id: 0,
        isActive: true,
        gender: "",
        dateOfBirth: "" || null,
        contactNumber: "",
        emails: [],
        medicalRecordNo: "",
        clientAffiliateId: null,
        cardNumber: "",
        governmentId: "",
        phAddresses: [],
        patientEmailForDisplay: [],
        fullPatientName: "",
        isPatientHasAnAcceptedOrder: false,
      })
    );

    dispatch(
      handleChangeMultiFields({
        id: 0,
        patientId: 0,
        providerId: null,
        clientWillDrawSpecimen: true,
        diagnosesList: [],
        specialHandlingIds: [],
        testOrderDetails: [],
        patientSpecimens: [],
      })
    );

    dispatch(
      handleChangeSpecimenFields({
        specimenType: "",
        collectionDateTime: "",
        collectorId: null,
        collectionSite: "",
        quantity: 1,
      })
    );

    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: [],
        savedLabtestsIdAndSpecimen: [],
        savedSpecimensToEdit: [],
        savedInvalidSpecimens: [],
        savedInvalidCollectionDateTime: [],
        showInvalidSpecimensModal: false,
      })
    );

    dispatch(
      handleChangeHandling({
        savedHandling: [],
      })
    );

    dispatch(
      handleChangeSavedFields({
        physicianCode: 0,
        physicianName: "",
        sampleCollectorName: "",
        sampleCollectorType: "",
        sampleCollectorId: null,
        searchPatientName: "",
        searchMedicalRecordNo: "",
        selectedPatientId: null,
        isPatientNull: false,
        isClinicalDiagnosisEmpty: false,
        isTestProfileEmpty: false,
        isSampleCollectorEmpty: false,
        isCollectionSiteEmpty: false,
        isPhysicianNull: false,
        isSpecimenQuantityEmpty: false,
      })
    );

    dispatch(
      handleChangeSavedTestInfo({
        savedTestInfo: [],
        savedRequirementDetails: [],
        fetchedInputFields: [],
        isSampleCollectorInputEmpty: false,
        isSampleOriginInputDirty: false,
      })
    );

    dispatch(
      handleChangePhysicianFields({
        id: 0,
        physicianFullName: "",
        firstName: "",
        lastName: "",
      })
    );

    dispatch(
      handleChangeSampleCollectorFields({
        id: 0,
        collectorType: "Phlebotomist",
        firstName: "",
        lastName: "",
        sampleCollectorFullName: "",
      })
    );

    dispatch(
      handleChangeOrderData({
        successfullyCreatedOrder: false,
        orderAgain: false,
        isOrderDataForUpdate: false,
        orderNo: "[pending]",
        orderStatus: "",
        selectedTestOrderId: 0,
        autoGeneratedOrderNumber: null,
        duplicatedTestOrderData: [],
        reasonInput: "",
        encryptedTestOrderData: null,
      })
    );
  };
  return { handleResetReduxStore };
};

export default useClearReduxStore;
