import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_CLIENT_AFFILIATES } from "config/connString";

const getAllClientAffiliates = createAsyncThunk(
  "client-affiliates/get-all-client-affiliates",
  async (data) => {
    try {
      const api = useAxios();
      const config = {
        params: {
          search: data.search,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          sortField: data.sortField,
          sortDirection: data.sortDirection,
        },
      };
      const response = await api.get(URL_CLIENT_AFFILIATES, config);
      return response.data;
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
);

export { getAllClientAffiliates };
