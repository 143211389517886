import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import styles from "../testRequest/styles/createPatientModalStyles/styles.module.css";
import { createPatient, updatePatient } from "store";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Switch } from "antd";
import {
  birthDateSkeleton,
  fullWidthSkeleton,
  genderSkeleton,
  inputSkeleton,
  statusSkeleton,
} from "./skeletonLoaders";
import useTranslation from "hooks/useTranslation";
import PopConfirm from "../global/popConfirm";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import useValidateEmail from "hooks/useValidateEmail";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/jkt/global/openNotification";
import errorMessagesDescription from "components/jkt/global/errorMessagesDescription";
import AddressSelector from "./addressSelector";
import { checkedData } from "utils/checkedData";
import { convertedFullName } from "utils/convertedFullName";
import { parsedClientDetails } from "utils/parsedClientDetails";
import AffiliateClientSearchInput from "../testRequest/forms/firstStep/affiliateClientSearchInput";

const CreateUpdatePatientModal = ({
  isPatientDataLoading,
  handleCloseModal,
  countryOptions,
  provinceOptions,
  cityMunicipalityOptions,
  barangayOptions,
  phAddressesTypeOptions,
  searchAffiliateClientInput,
  setSearchAffiliateClientInput,
  affliateClientDataForUpdate,
  setAffiliateClientDataForUpdate,
}) => {
  const inputs = useSelector((state) => state.inputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const location = useLocation();
  const { getTranslation, getGlobalTranslation } =
    useTranslation("PatientPage");
  const { setErrorMessage, errorMessage, isEmailValid } =
    useValidateEmail(inputEmail);
  const isPatientForUpdate = multiStepFormInputs.patientId !== 0;
  const emailRef = useRef();
  const dispatch = useDispatch();
  const { checkIfProvidedDateInFuture } = useCheckProvidedDate();
  const { clientDetails } = parsedClientDetails();
  const isRequiredInputsEmpty =
    inputs.fullPatientName.trim().length === 0 ||
    !inputs.dateOfBirth ||
    !inputs.gender;
  const isProvidedDateOfBirthInFuture = checkIfProvidedDateInFuture(
    inputs.dateOfBirth
  );

  const threeGridOptions = {
    columnSpacing: 2,
    rowSpacing: 1,
    sx: { marginTop: "1rem", marginBottom: "1rem" },
  };

  const threeGrid = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 4,
    xl: 4,
  };

  const formInputStyle = {
    width: "100%",
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "2px solid",
        borderColor: "darkBlue.secondary",
      },
    },
  };

  const inputLabelStyle = {
    fontWeight: "bold",
    color: "darkBlue.main",
  };

  const handleAddEmail = (selectedEmail) => {
    if (inputEmail && isEmailValid()) {
      dispatch(
        handleChangeFields({
          emails: [...inputs.emails, selectedEmail],
        })
      );
      setInputEmail("");
    }
  };

  const handleCloseFormModal = () => {
    handleCloseModal();
    setIsFormDirty(false);
  };

  const handleEnterEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputEmail && isEmailValid()) {
        dispatch(
          handleChangeFields({
            emails: [...inputs.emails, e.target.value],
          })
        );
        setInputEmail("");
      }
    }
    if (
      inputEmail === "" &&
      e.key === "Backspace" &&
      inputs.emails.length > 0
    ) {
      const updatedEmail = [...inputs.emails];
      updatedEmail.pop();
      dispatch(handleChangeFields({ emails: updatedEmail }));
    }
  };

  const handleRemoveEmail = (selectedEmail) => {
    const updatedEmails = inputs.emails.filter((m) => m !== selectedEmail);
    dispatch(handleChangeFields({ emails: updatedEmails }));
  };

  const showErrorMessage = (inputName, displayName) => {
    if (!inputName && isFormDirty) {
      return (
        <Typography
          sx={{ color: "red", fontSize: "0.8rem", marginTop: "0.4rem" }}
          variant="p"
        >
          {displayName} {getGlobalTranslation("is required")}
        </Typography>
      );
    } else {
      return;
    }
  };

  const handleSelectStatus = (value) => {
    dispatch(
      handleChangeFields({
        isActive: value,
      })
    );
  };

  const handleValidateFormInputs = () => {
    if (isRequiredInputsEmpty) {
      return setIsFormDirty(true);
    }
  };

  const handleCreatePatient = () => {
    handleValidateFormInputs();
    const {
      id,
      isActive,
      isPatientHasAnAcceptedOrder,
      patientEmailForDisplay,
      fullPatientName,
      ...formattedData
    } = inputs;
    if (createPatient.pending().type === "patients/create-patient/pending") {
      setIsLoading(true);
    }
    const { firstName, lastName } = convertedFullName(inputs.fullPatientName);

    dispatch(
      createPatient({
        ...formattedData,
        firstname: firstName,
        lastname: lastName,
        clientAffiliateId: inputs?.clientAffiliateId,
      })
    ).then((response) => {
      const isFetchSuccess = response?.payload?.success;
      const responseData = response?.payload?.data;
      if (isFetchSuccess) {
        dispatch(
          handleChangeSavedFields({
            refetchData: !savedInputs.refetchData,
          })
        );
        dispatch(
          handleChangeFields({
            id: responseData?.id,
            isActive: responseData?.isActive,
            patientEmailForDisplay: responseData?.emails,
          })
        );
        if (location.pathname === "/test-request") {
          dispatch(
            handleChangeSavedFields({
              dateOfBirth: moment(responseData?.dateOfBirth),
              searchPatientName: `${responseData?.firstname} ${checkedData(
                responseData?.lastname
              )}`,
              searchMedicalRecordNo: `${checkedData(
                responseData?.medicalRecordNo
              )}`,
              gender: responseData?.gender,
              isPatientNull: false,
            })
          );
          dispatch(
            handleChangeMultiFields({
              patientId: responseData?.id,
            })
          );
        }

        setIsLoading(false);
        notification.success({
          message: getGlobalTranslation("Added Patient"),
          description: `${inputs.fullPatientName} ${getGlobalTranslation(
            "added successfully"
          )}`,
        });
        handleCloseModal();
      }
      if (!isFetchSuccess) {
        setIsLoading(false);
        notification.error({
          message: "Failed to add patient",
          description: errorMessagesDescription(
            response?.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  const handleUpdatePatient = () => {
    handleValidateFormInputs();
    const {
      isPatientHasAnAcceptedOrder,
      patientEmailForDisplay,
      fullPatientName,
      ...formattedData
    } = inputs;
    setTimeout(() => {
      dispatch(
        handleChangeSavedFields({
          refetchData: !savedInputs.refetchData,
        })
      );
    }, 500);
    if (updatePatient.pending().type === "patients/update-patient/pending") {
      setIsLoading(true);
    }

    const { firstName, lastName } = convertedFullName(inputs.fullPatientName);

    dispatch(
      updatePatient({
        ...formattedData,
        firstname: firstName,
        lastname: lastName,
        clientAffiliateId: inputs?.clientAffiliateId,
      })
    ).then((response) => {
      const isFetchSuccess = response?.payload?.success;
      const responseData = response?.payload?.data;

      if (isFetchSuccess) {
        dispatch(
          handleChangeSavedFields({
            refetchData: !savedInputs.refetchData,
          })
        );
        if (location.pathname === "/test-request") {
          dispatch(
            handleChangeFields({
              patientEmailForDisplay: responseData?.emails,
            })
          );
          dispatch(
            handleChangeSavedFields({
              searchPatientName: `${responseData?.firstname} ${checkedData(
                responseData?.lastname
              )}`,
              setSearchMedicalRecordNo: `${checkedData(
                responseData?.medicalRecordNo
              )}`,
              searchMedicalRecordNo: checkedData(responseData?.medicalRecordNo),
              medicalRecordNo: checkedData(responseData?.medicalRecordNo),
            })
          );
        }

        notification.success({
          message: "Updated Patient",
          description: `Successfully updated ${
            responseData?.firstname
          } ${checkedData(responseData?.lastname)}`,
        });
        setIsFormDirty(false);
        handleCloseModal();
        setIsLoading(false);
      }
      if (!isFetchSuccess) {
        notification.error({
          message: "Failed to update patient",
          description: errorMessagesDescription(
            response?.payload?.response?.data?.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      multiStepFormInputs.patientId === 0 &&
      location.pathname === "/test-request"
    ) {
      dispatch(
        handleChangeFields({
          cardNumber: "",
          fullPatientName: "",
          gender: "",
          dateOfBirth: "",
          contactNumber: "",
          emails: [],
          medicalRecordNo: "",
          phAddresses: [],
          governmentId: "",
          isPatientHasAnAcceptedOrder: false,
        })
      );
    }
  }, [location.pathname, dispatch, multiStepFormInputs.patientId]);

  return (
    <Box sx={{ padding: 3 }}>
      <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
        <Typography
          variant="h6"
          display="block"
          sx={{ fontWeight: "500", color: "#294991" }}
        >
          {isPatientForUpdate
            ? getTranslation("Edit Patient")
            : getTranslation("Add Patient")}
        </Typography>
      </Divider>
      <Grid {...threeGridOptions} container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography variant="p" sx={inputLabelStyle} className="required">
            {getTranslation("Patient Name")}
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              disabled={inputs.isPatientHasAnAcceptedOrder}
              error={!inputs.fullPatientName && isFormDirty}
              sx={formInputStyle}
              value={inputs.fullPatientName}
              autoSave="off"
              placeholder={`${getGlobalTranslation("Enter your patient name")}`}
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(
                  handleChangeFields({
                    fullPatientName: e.target.value,
                  })
                )
              }
            />
          )}
          {showErrorMessage(
            inputs.fullPatientName,
            getTranslation("Patient Name")
          )}
        </Grid>
      </Grid>
      <Grid {...threeGridOptions} container>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            Client of the Client
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            // <TextField
            //   disabled={true}
            //   sx={formInputStyle}
            //   value={inputs.cardNumber}
            //   autoSave="off"
            //   placeholder={getGlobalTranslation(
            //     "Enter your abc lab member card number"
            //   )}
            //   size="small"
            //   variant="outlined"
            //   onChange={(e) =>
            //     dispatch(handleChangeFields({ cardNumber: e.target.value }))
            //   }
            // />
            <AffiliateClientSearchInput
              searchAffiliateClientInput={searchAffiliateClientInput}
              setSearchAffiliateClientInput={setSearchAffiliateClientInput}
              affliateClientDataForUpdate={affliateClientDataForUpdate}
              setAffiliateClientDataForUpdate={setAffiliateClientDataForUpdate}
            />
          )}
        </Grid>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            {getTranslation("Contact Number")}
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              sx={formInputStyle}
              value={inputs.contactNumber}
              autoSave="off"
              placeholder={getGlobalTranslation("Enter your contact number")}
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(handleChangeFields({ contactNumber: e.target.value }))
              }
            />
          )}
        </Grid>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            {getTranslation("Medical Record No")}
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              sx={formInputStyle}
              value={inputs.medicalRecordNo}
              autoSave="off"
              placeholder={getGlobalTranslation("Enter your medical record no")}
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(
                  handleChangeFields({ medicalRecordNo: e.target.value })
                )
              }
            />
          )}
        </Grid>
      </Grid>
      <Divider textAlign="center" sx={{ margin: "2rem 0" }}>
        <Chip label={getTranslation("Address")} />
      </Divider>
      <AddressSelector
        countryOptions={countryOptions}
        phAddressesTypeOptions={phAddressesTypeOptions}
        provinceOptions={provinceOptions}
        cityMunicipalityOptions={cityMunicipalityOptions}
        barangayOptions={barangayOptions}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1.5rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl error={!inputs.gender && isFormDirty}>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              {getTranslation("Sex at Birth")}
            </Typography>
            {isPatientDataLoading ? (
              genderSkeleton
            ) : (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  disabled={inputs.isPatientHasAnAcceptedOrder}
                  value="Male"
                  checked={inputs.gender === "Male"}
                  onChange={(e) =>
                    dispatch(handleChangeFields({ gender: e.target.value }))
                  }
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                          {
                            color:
                              !inputs.gender && isFormDirty
                                ? "red"
                                : "darkBlue.secondary",
                          },
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  label={getTranslation("Male")}
                />
                <FormControlLabel
                  disabled={inputs.isPatientHasAnAcceptedOrder}
                  checked={inputs.gender === "Female"}
                  onChange={(e) =>
                    dispatch(handleChangeFields({ gender: e.target.value }))
                  }
                  value="Female"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                          {
                            color:
                              !inputs.gender && isFormDirty
                                ? "red"
                                : "darkBlue.secondary",
                          },
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  label={getTranslation("Female")}
                />
              </RadioGroup>
            )}
          </FormControl>
          {showErrorMessage(inputs.gender, getTranslation("Sex at Birth"))}
        </Box>
        <Box
          sx={{
            display: isPatientForUpdate ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            Status
          </Typography>
          {isPatientDataLoading ? (
            statusSkeleton
          ) : (
            <Switch
              onChange={handleSelectStatus}
              checked={inputs.isActive}
              checkedChildren={getTranslation("Active")}
              unCheckedChildren={getTranslation("Inactive")}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            className="required"
          >
            {getTranslation("DateOfBirth")}
          </Typography>
          {isPatientDataLoading ? (
            birthDateSkeleton
          ) : (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={inputs.isPatientHasAnAcceptedOrder}
                slotProps={{
                  textField: {
                    error:
                      (!inputs.dateOfBirth && isFormDirty) ||
                      (inputs.dateOfBirth && isProvidedDateOfBirthInFuture),
                    size: "small",
                    placeholder: getGlobalTranslation("Date Format"),
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.secondary",
                    },
                  },
                }}
                value={inputs.dateOfBirth || null}
                onChange={(newValue) =>
                  dispatch(handleChangeFields({ dateOfBirth: newValue }))
                }
                format="DD/MM/YYYY"
                inputFormat="DD/MM/YYYY"
                disableFuture={true}
                slots={{
                  openPickerIcon: CalendarMonthIcon,
                }}
              />
            </LocalizationProvider>
          )}
          {showErrorMessage(inputs.dateOfBirth, getTranslation("DateofBirth"))}
          {inputs.dateOfBirth && isProvidedDateOfBirthInFuture && (
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
              }}
            >
              {getGlobalTranslation("Date of birth cannot be set in future")}
            </Typography>
          )}
        </Box>
      </Box>
      {clientDetails.clientConfig.allowEmailSendingOfRequest && (
        <Box sx={{ marginTop: "1.5rem", position: "relative" }}>
          <Typography variant="p" sx={inputLabelStyle}>
            {getGlobalTranslation("Email Address")}
          </Typography>
          {isPatientDataLoading ? (
            fullWidthSkeleton
          ) : (
            <div
              onKeyDown={handleEnterEmail}
              tabIndex={1}
              className={styles["email-input-wrapper"]}
              onClick={() => emailRef.current.focus()}
            >
              <ul>
                {inputs?.emails?.map((email) => (
                  <li>
                    <Chip
                      color="primary"
                      variant="outline"
                      avatar={<Avatar>{email.charAt(0).toUpperCase()}</Avatar>}
                      label={email}
                      onDelete={() => handleRemoveEmail(email)}
                    />
                  </li>
                ))}
              </ul>
              <input
                ref={emailRef}
                type="text"
                name="emails"
                value={inputEmail}
                onKeyDown={(e) => handleEnterEmail(e)}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                  setErrorMessage("");
                }}
                autoComplete="false"
                placeholder={getGlobalTranslation("Enter your email address")}
              />
            </div>
          )}
          {errorMessage && (
            <p className={styles["error-message"]}>{errorMessage}</p>
          )}
          {inputEmail?.includes("@") &&
            inputEmail?.length > 6 &&
            !errorMessage && (
              <div className={styles["email-suggestion-box"]}>
                <p
                  onKeyDown={() => handleAddEmail(inputEmail)}
                  tabIndex={0}
                  onClick={() => handleAddEmail(inputEmail)}
                >
                  {inputEmail}
                </p>
              </div>
            )}
        </Box>
      )}
      <Box sx={{ marginTop: "1.5rem" }}>
        <Typography variant="p" sx={inputLabelStyle}>
          {getTranslation("Government ID")}
        </Typography>
        {isPatientDataLoading ? (
          fullWidthSkeleton
        ) : (
          <TextField
            onChange={(e) =>
              dispatch(handleChangeFields({ governmentId: e.target.value }))
            }
            sx={formInputStyle}
            value={inputs.governmentId}
            autoSave="off"
            placeholder={getGlobalTranslation("Enter your government ID")}
            size="small"
            variant="outlined"
          />
        )}
      </Box>
      {inputs.isPatientHasAnAcceptedOrder && (
        <Box
          sx={{
            marginBottom: "1.3rem",
            marginTop: "1.5rem",
            textAlign: "center",
            px: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontStyle: "italic",
              borderLeft: "2px solid red",
              paddingLeft: "0.3rem",
            }}
            variant="p"
          >
            Some input fields cannot be updated and are disabled because{" "}
            <Typography sx={{ fontWeight: "bold" }} variant="p">
              ABC Laboratories
            </Typography>{" "}
            has already accepted this patient's test order. For any concerns,
            please contact our Client Service.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 5,
          marginTop: !inputs.isPatientHasAnAcceptedOrder && "1.5rem",
        }}
      >
        {!isRequiredInputsEmpty && (
          <PopConfirm
            cancelText="Cancel"
            description={getGlobalTranslation(
              "Are you sure that all the information you input is correct"
            )}
            okText="Confirm"
            onConfirm={
              isPatientForUpdate ? handleUpdatePatient : handleCreatePatient
            }
            placement="leftTop"
            title={getGlobalTranslation("Please double check input fields")}
          >
            <LoadingButton
              size="medium"
              type="button"
              loading={isLoading}
              variant="contained"
              endIcon={isPatientForUpdate ? <SaveIcon /> : <SendIcon />}
            >
              {isPatientForUpdate
                ? getGlobalTranslation("Save Changes")
                : getGlobalTranslation("Submit")}
            </LoadingButton>
          </PopConfirm>
        )}
        {isRequiredInputsEmpty && (
          <LoadingButton
            size="medium"
            type="button"
            loading={isLoading}
            variant="contained"
            endIcon={isPatientForUpdate ? <SaveIcon /> : <SendIcon />}
            onClick={
              isPatientForUpdate ? handleUpdatePatient : handleCreatePatient
            }
          >
            {isPatientForUpdate
              ? getGlobalTranslation("Save Changes")
              : getGlobalTranslation("Submit")}
          </LoadingButton>
        )}
        <Button
          onClick={handleCloseFormModal}
          size="medium"
          variant="dark"
          endIcon={<CloseIcon />}
        >
          {getGlobalTranslation("Cancel")}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdatePatientModal;
