import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const updateUser = createAsyncThunk("user/update-user", async (data) => {
  try {
    const api = useAxios();

    const response = await api.put(URL_USER + "/" + data[0], {
      id: data[0],
      userName: data[1].userName,
      email: data[1].email,
      displayName: data[1].displayName,
      clientId: data[1].clientId,
      roles: data[1].roles,
      isActive: data[1].isActive,
      forcePasswordChange: data[1].forcePasswordChange,
      courierCode: data[1]?.courierCode,
    });

    return response.data;
  } catch (error) {
    return error;
  }
});

export { updateUser };
