import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_AFFILIATES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const createClientAffiliate = createAsyncThunk(
  "client-affiliates/create-client-affiliate",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.post(URL_CLIENT_AFFILIATES, {
        affiliateCode: data?.affiliateCode,
        affiliateName: data?.affiliateName,
        clientId: clientId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { createClientAffiliate };
