import { BASE_URL } from "constants/connections";

export const VERSION = "v1";

const renderUrlConnectionString = (value, apiVersion = "v1") => {
  const combinedConnectionString = `${BASE_URL}/${
    apiVersion !== VERSION ? apiVersion : VERSION
  }${value}`;
  return combinedConnectionString;
};

//Version 1
export const URL_AUTH = renderUrlConnectionString("/identity/");
export const URL_CLIENT = renderUrlConnectionString("/client-types/");
export const URL_PATIENT = renderUrlConnectionString("/patients");
export const URL_USER = renderUrlConnectionString("/users");
export const URL_ROLE_CLAIMS = renderUrlConnectionString("/roles");
export const URL_CLIENT_INFO = renderUrlConnectionString("/clients");
export const URL_CLIENT_AFFILIATES =
  renderUrlConnectionString("/client-affiliates");
export const URL_PROVINCES = renderUrlConnectionString("/provinces");
export const URL_CITY_MUNICIPALITY = renderUrlConnectionString(
  "/cities-municipalities"
);
export const URL_BARANGAY = renderUrlConnectionString("/barangays");
export const URL_LAB_TESTS = renderUrlConnectionString("/tests");
export const URL_LAB_DEPT = renderUrlConnectionString("/lab-departments");
export const URL_HEALTHCARE_PROVIDER = renderUrlConnectionString(
  "/healthcare-providers"
);
export const URL_COUNTRIES = renderUrlConnectionString("/countries");
export const URL_ICD10CODES = renderUrlConnectionString("/icd10-codes");
export const URL_SPECIAL_HANDLING =
  renderUrlConnectionString("/special-handlings");
export const URL_LOGS = renderUrlConnectionString("/logs");
export const URL_SAMPLE_COLLECTORS =
  renderUrlConnectionString("/sample-collectors");
export const URL_TEST_ORDERS = renderUrlConnectionString("/test-orders");
export const URL_PH_ADD = renderUrlConnectionString("/philippine-addresses");
export const URL_SPECIMEN = renderUrlConnectionString("/specimens");
export const URL_REGISTER_QRCODE =
  renderUrlConnectionString("/register-qrcode");
export const URL_ATTACHMENTS = renderUrlConnectionString("/file");
export const URL_SUPPLY_ORDER = renderUrlConnectionString("/supply-orders");
export const URL_TEST_ORDER_ENCRYPTION = renderUrlConnectionString("/encrypt");
export const URL_TEST_ORDER_DECRYPTION =
  renderUrlConnectionString("/decrypt-testOrder");
export const URL_EXTERNAL_SYSTEM_CREDENTIALS = renderUrlConnectionString(
  "/external-system-credentials"
);

export const URL_REQUIREMENT_TEMPLATES = renderUrlConnectionString(
  "/requirement-templates"
);

export const URL_SYSTEM_CONFIGURATION = renderUrlConnectionString(
  "/system-configuration"
);
export const CURRENT_URL = window.location.hostname.includes("localhost")
  ? window.location.hostname + ":3000"
  : window.location.hostname;

//Version 2
export const V2_URL_TEST_ORDERS = renderUrlConnectionString(
  "/test-orders",
  "v2"
);
