import { createAsyncThunk } from "@reduxjs/toolkit";
import useAxios from "../../utils/useAxios";
import { URL_CLIENT_AFFILIATES } from "../../config/connString";

const getClientAffiliateInfo = createAsyncThunk(
  "client-affiliates/get-client-affiliate-info",
  async (id) => {
    try {
      const api = useAxios();
      const response = await api.get(URL_CLIENT_AFFILIATES + "/" + id);
      return response.data;
    } catch (err) {
      return err;
    }
  }
);

export { getClientAffiliateInfo };
