import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_AFFILIATES } from "config/connString";
import useAxios from "utils/useAxios";

const clientAffiliatesLookUp = createAsyncThunk(
  "client-affiliates/client-affiliates-lookup",
  async (clientId) => {
    try {
      const api = useAxios();
      const response = await api.get(
        `${URL_CLIENT_AFFILIATES}/${clientId}/affiliates-lookup`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { clientAffiliatesLookUp };
