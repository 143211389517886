import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_CLIENT_AFFILIATES } from "../../config/connString";
import useAxios from "../../utils/useAxios";
import decodedUserDetails from "utils/decodedUserDetails";

const updateClientAffiliate = createAsyncThunk(
  "client-affiliates/update-client-affiliate",
  async (data) => {
    try {
      const clientId = decodedUserDetails()?.clientId;
      const api = useAxios();

      const response = await api.put(`${URL_CLIENT_AFFILIATES}/${data?.id}`, {
        id: data?.id,
        affiliateCode: data?.affiliateCode,
        affiliateName: data?.affiliateName,
        clientId: clientId,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export { updateClientAffiliate };
