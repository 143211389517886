import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_USER } from "../../config/connString";
import useAxios from "../../utils/useAxios";
const createUser = createAsyncThunk("user/create-users", async (data) => {
  try {
    const api = useAxios();
    const response = await api.post(URL_USER, {
      userName: data?.userName,
      email: data?.email,
      password: data?.password === "" ? null : data?.password,
      displayName: data?.displayName,
      clientId: data?.clientId,
      roles: data?.roles,
      courierCode: data?.courierCode,
    });
    return response.data;
  } catch (error) {
    return error;
  }
});

export { createUser };
